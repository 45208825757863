const timelineElements = [
    {
        id: 1,
        title: "Bachelor's Degree, Engineering",
        location: "Anna University, India",
        description: "Graduated with a Bachelor's degree in Engineering with a GPA of 3.4 out of 4 at Anna University.",
        buttonText: "View Projects",
        link: "/projects",
        date: "Aug 2013-July 2017",
        icon: "school",
        color: "blue",
        tech: ["JavaScript", "HTML", "CSS", "Java"],
    },
    {
        id: 2,
        title: "Software Engineer",
        location: "Tata Consultancy Services, India",
        description: "Developed and optimized front-end components with ReactJS and Redux, and migrated legacy applications to modern frameworks like ASP.Net MVC and AngularJS. Implemented CI/CD pipelines, scalable APIs with NodeJS, and optimized databases for enhanced system efficiency.",
        buttonText: "Details",
        link: "/article/3",
        date: "Oct 2017-Nov 2021",
        icon: "work",
        color: "purple",
        tech: ["JavaScript", "React", "Node.js", "SQL"],
    },
    {
        id: 3,
        title: "Master's Degree, Computer Science",
        location: "San Francisco State University, CA",
        description: "Graduated with a Master's degree in Computer Sciencer with a GPA of 3.8 out of 4 at San Francisco State University(SFSU).",
        buttonText: "View Projects",
        link: "/projects",
        date: "Jan 2022-Dec 2023",
        icon: "school",
        color: "blue",
        tech: ["JavaScript", "HTML", "CSS", "Java"],
    },
    {
        id: 4,
        title: "Software Engineer Intern",
        location: "KQED Public Media, CA",
        description: "Migrated business data from a legacy SQL- based CRM to MongoDB, enhancing efficiency and accessibility.Developed Python pipelines for seamless data synchronization and created Tableau dashboards to improve visualization and decision - making.",
        buttonText: "Details",
        link: "/article/2",
        date: "July 2023-Dec 2023",
        icon: "work",
        color: "red",
        tech: ["Node.js", "JavaScript", "SQL", "Python"],
    },
    {
        id: 5,
        title: "Software Engineer II",
        location: "Cardinality.ai, MD",
        description: "Developed custom components with Form.io, designed RESTful APIs using Node.js, and built dynamic frontends with Angular. Optimized PostgreSQL views and collaborated with teams to deliver scalable, high-performance solutions.",
        buttonText: "Details",
        link: "/article/1",
        date: "Feb 2024-present",
        icon: "work",
        color: "orange",
        tech: ["JavaScript", "React", "HTML", "CSS", "Python"],
    },
];

export default timelineElements.reverse();